<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.presentationOfBookings') }}</h3>
    <toggle :data="anonym[data.exclude.anonym]" :onChange="function (data) { setAnonym(data) }">
      <template v-slot:0>{{ anonym[0] }}</template>
      <template v-slot:1>{{ anonym[1] }}</template>
    </toggle>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    callback: Function
  },
  data () {
    return {
      anonym: [
        'Öffentlich einsehbar',
        'Anonymisieren'
      ]
    }
  },
  methods: {
    setAnonym (data) {
      this.anonym.forEach(function (v, k) {
        if (v === data.target.innerHTML) {
          this.callback(k)
        }
      }.bind(this))
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
